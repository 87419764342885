//@author devin, travis
import { DataAccess } from "../dataaccess/data.access";
import { S25Util } from "../util/s25-util";
import { Cache, Invalidate } from "../decorators/cache.decorator";
import { PricingService } from "./pricing.service";
import { Timeout } from "../decorators/timeout.decorator";
import { FormulaI, RateScheduleI, RateSchedulePayload } from "../pojo/RateScheduleI";
import { FlsService } from "./fls.service";
import { DurationI } from "../pojo/DurationI";
import { S25ItemI } from "../pojo/S25ItemI";
import PayloadI = RateSchedulePayload.PayloadI;

const rateTypeMap: any = {
    1: "event_type",
    2: "service", //requirement
    3: "space",
    4: "resource",
};
export class RateService {
    @Timeout
    @Cache({ immutable: true, targetName: "RateService" })
    public static getRateGroups() {
        return PricingService.getRatesAndTaxes().then(function (data) {
            return (data && data.root && data.root.rate_groups && data.root.rate_groups.rate_group) || [];
        });
    }

    @Timeout
    public static getActiveRateGroups() {
        return RateService.getRateGroups().then((data) => {
            return data.filter((r: any) => {
                return r.defn_state != 0;
            });
        });
    }

    @Timeout
    public static getFilteredRateGroups() {
        return FlsService.getFls().then((fls) => {
            return fls.ML_PRICE != "F" ? RateService.getActiveRateGroups() : RateService.getRateGroups();
        });
    }

    public static getRateGroupItems() {
        return RateService.getRateGroups().then(function (rateGroups) {
            return rateGroups.map(function (rg: any) {
                return { itemId: rg.rate_group_id, itemName: rg.rate_group_name };
            });
        });
    }

    @Cache({ immutable: true, targetName: "RateService" })
    public static getRateSchedules(billTypeId?: number, billId?: number) {
        let url = "/rates.json?";
        if (billTypeId) {
            url += "type_id=" + billTypeId + "&";
        }
        if (billId) {
            url += "object_id=" + billId + "&";
        }

        return DataAccess.get(url).then(function (data) {
            data = S25Util.prettifyJson(data, null, { rate: true });
            let resp = (data && data.rates && data.rates.rate) || [];
            resp.forEach((r: any) => {
                let type = RateService.getItemTypeName(r.type_id);
                r.type = type.itemTypeName;
            });
            return resp;
        });
    }

    @Timeout
    public static getRateSchedule(rateId: number | string, mode?: "create" | "edit") {
        let url = "/rate.json?rate_id=" + rateId;
        url += mode ? "&mode=" + mode : "";
        return DataAccess.get(url).then((data) => {
            data = S25Util.prettifyJson(data);
            return data && data.rate && data.rate.rate_schedule && data.rate.rate_schedule[0];
        });
    }

    public static getRateScheduleObj(rateId: number | string, mode?: "create" | "edit") {
        return RateService.getRateSchedule(rateId, mode).then((data) => {
            return RateService.payloadToObj(data);
        });
    }

    @Timeout
    @Invalidate({ serviceName: "RateService" })
    public static setRateSchedule(rateId: number | string, rateSchedule: RateScheduleI) {
        let payload = RateService.objToPayload(rateSchedule);
        let payloadWrapper = { rate: { rate_schedule: payload } };
        if (rateId) {
            return DataAccess.put("/rate.json?rate_id=" + rateId, payloadWrapper).then((data) => {
                return data;
            });
        } else {
            return DataAccess.post("/rates.json", payloadWrapper).then((data) => {
                return data;
            });
        }
    }

    @Timeout
    @Invalidate({ serviceName: "RateService" })
    public static deleteRateSchedule(rateId: number | string) {
        return DataAccess.delete("/rate.json?rate_id=" + rateId);
    }

    public static payloadToObj(payload: RateSchedulePayload.PayloadI): RateScheduleI {
        let obj: RateScheduleI = {
            itemName: S25Util.toStr(payload.rate_name),
            itemId: S25Util.parseInt(payload.rate_id),
            itemTypeId: S25Util.parseInt(payload.rate_type_id) as RateScheduleI["itemTypeId"],
            itemTypeName: S25Util.toStr(payload.rate_type_name),
            favorite: S25Util.toBool(payload.favorite),
            creditAcctCode: S25Util.toStr(payload.credit_acct_code),
            debitAcctCode: S25Util.toStr(payload.debit_acct_code),
            status: payload.status,
            priceSheets: [],
            // rateGroups: [],
            taxes: [],
            billingItems: [],
        };

        //organize priceSheets by the associated rate group
        payload.price_sheet = S25Util.array.forceArray(payload.price_sheet);
        obj.priceSheets =
            payload.price_sheet.map((sheet: any) => {
                return {
                    version: sheet.version,
                    rateGroupId: sheet.rate_group_id,
                    rateGroupName: sheet.rate_group_name,
                    startDt: sheet.effective_date,
                    description: { data: sheet.description },
                    formula: RateService.formulaToObj(sheet.rate_formula),
                };
            }) || [];

        payload.tax = S25Util.array.forceArray(payload.tax);
        obj.taxes =
            payload.tax.map((element: any) => {
                return {
                    itemId: element.tax_id,
                    itemName: element.tax_name,
                };
            }) || [];

        //Normalize the billing items to a single property (less looping on the front end)
        payload.event_type &&
            payload.event_type.forEach((element: any) => {
                obj.billingItems.push({
                    itemName: element.type_name,
                    itemId: element.type_id,
                    itemTypeId: 1,
                });
            });
        payload.service &&
            payload.service.forEach((element: any) => {
                obj.billingItems.push({
                    itemName: element.service_name,
                    itemId: element.service_id,
                    itemTypeId: 2,
                });
            });
        payload.space &&
            payload.space.forEach((element: any) => {
                obj.billingItems.push({
                    itemName: element.space_name,
                    itemId: element.space_id,
                    itemTypeId: 3,
                });
            });
        payload.resource &&
            payload.resource.forEach((element: any) => {
                obj.billingItems.push({
                    itemName: element.resource_name,
                    itemId: element.resource_id,
                    itemTypeId: 4,
                });
            });

        obj.billingItems = S25Util.array.forceArray(obj.billingItems);

        return obj;
    }

    //Logic migrated from bahaviors.xml in admin tool
    public static formulaToObj(origFormula: RateSchedulePayload.FormulaI[]) {
        origFormula = S25Util.array.forceArray(origFormula);
        let newFormula: FormulaI[] = origFormula.map((form) => {
            if (
                (form.min === "0.0.0" && form.max === "1000.0.0") ||
                (form.min == "" && form.max == "") ||
                S25Util.isUndefined(form.min) ||
                S25Util.isUndefined(form.max)
            ) {
                return {
                    rangeType: "none",
                    durStart: null,
                    durEnd: null,
                    expression: form.formula,
                    break_point: form.break_point,
                    status: form.status || "est",
                };
            } else if (form.min && form.min.startsWith("2000") && form.max && form.max.startsWith("2000")) {
                //ex: 2000.17.0 = 5pm (flag.hours.minutes)

                let startArr = form.min.split(".");
                let endArr = form.max.split(".");

                let stHours = parseInt(startArr[1]);
                let stMinutes = parseInt(startArr[2]);

                let endHours = parseInt(endArr[1]);
                let endMinutes = parseInt(endArr[2]);

                let startTime = stHours + ":" + stMinutes;
                let endTime = endHours + ":" + endMinutes;

                return {
                    rangeType: "time",
                    durStart: startTime,
                    durEnd: endTime,
                    expression: form.formula,
                    break_point: form.break_point,
                    status: form.status || "est",
                };
            } else {
                //eg: 1.5.3 (d.h.m)
                let dhmMin, dhmMax: any;
                let sMin = form.min.split(".");
                let sMax = form.max.split(".");

                dhmMin = {
                    days: S25Util.toInt(sMin[0]),
                    hours: S25Util.toInt(sMin[1]),
                    minutes: S25Util.toInt(sMin[2]),
                };
                dhmMax = {
                    days: S25Util.toInt(sMax[0]),
                    hours: S25Util.toInt(sMax[1]),
                    minutes: S25Util.toInt(sMax[2]),
                };

                return {
                    rangeType: "duration",
                    durStart: dhmMin,
                    durEnd: dhmMax,
                    expression: form.formula,
                    break_point: form.break_point,
                    status: form.status || "est",
                };
            }
        });

        return newFormula;
    }

    public static objToPayload(srcObj: RateScheduleI, filterChanges = true) {
        let obj: RateScheduleI = S25Util.deepCopy(srcObj);
        let rate_schedule: RateSchedulePayload.PayloadI = {
            rate_id: obj.itemId || "",
            rate_name: obj.itemName || "",
            rate_type_id: obj.itemTypeId || "",
            rate_type_name: obj.itemTypeName || "",
            credit_acct_code: obj.creditAcctCode || "",
            debit_acct_code: obj.debitAcctCode || "",
            status: obj.itemId ? obj.status || "mod" : "new",
            price_sheet: [],
            tax: [],
            resource: [],
            space: [],
            service: [],
            event_type: [],
        };

        obj.priceSheets = S25Util.array.forceArray(obj.priceSheets);
        obj.priceSheets.forEach((el) => {
            let effective_date;
            if (S25Util.date.isDate(el.startDt)) {
                effective_date = el.startDt.toISOString().split("T")[0]; //"2021-09-21";
            } else {
                effective_date = el.startDt;
            }

            let formula = el.formula.map((f) => {
                let newF: any = {
                    status: f.status,
                    break_point: f.break_point,
                    formula: f.expression,
                };
                if (!f.rangeType || f.rangeType === "none" || f.durStart == f.durEnd) {
                    newF.min = "0.0.0";
                    newF.max = "1000.0.0";
                } else if (f.rangeType === "time") {
                    let min = S25Util.date.parse(f.durStart as string);
                    let max = S25Util.date.parse(f.durEnd as string);
                    newF.min = min && "2000." + (min.getHours() || "0") + "." + (min.getMinutes() || "0");
                    newF.max = max && "2000." + (max.getHours() || "0") + "." + (max.getMinutes() || "0");
                } else if (f.rangeType == "duration") {
                    let min = f.durStart as DurationI;
                    let max = f.durEnd as DurationI;
                    newF.min = (min && min.days + "." + min.hours + "." + min.minutes) || "0.0.0";
                    newF.max = (max && max.days + "." + max.hours + "." + max.minutes) || "0.0.0";
                }
                return newF;
            });

            let sheet = {
                rate_group_id: el.rateGroupId,
                rate_group_name: el.rateGroupName,
                version: el.version,
                effective_date: effective_date.toString(),
                description: el.description && el.description.data,
                rate_formula: formula,
                status: el.status || "est",
            };
            rate_schedule.price_sheet.push(sheet);
        });

        //'version' is the unique identifier for price sheets
        rate_schedule.price_sheet.forEach((sheet: any) => {
            if (sheet.version === 0) {
                sheet.version =
                    Math.max(...rate_schedule.price_sheet.map((s: any) => S25Util.parseInt(s.version)), 0) + 1;
            }
        });

        obj.taxes = S25Util.array.forceArray(obj.taxes);
        rate_schedule.tax = obj.taxes.map((tax: any) => {
            return {
                tax_id: tax.itemId,
                tax_name: tax.itemName,
                status: tax.status,
            };
        });

        //Central billing items back to the seperated WS friendly format - once pricing formula cannot be associated with more than one billing object type
        obj.billingItems = S25Util.array.forceArray(obj.billingItems);
        obj.billingItems.forEach((item) => {
            let itemType = rateTypeMap[obj.itemTypeId];
            if (S25Util.parseInt(obj.itemTypeId) === 1) {
                rate_schedule.event_type.push({
                    type_id: item.itemId,
                    type_name: item.itemName,
                    status: item.status,
                });
            } else {
                rate_schedule[itemType].push({
                    [itemType + "_id"]: item.itemId,
                    [itemType + "_name"]: item.itemName,
                    status: item.status,
                });
            }
        });
        return filterChanges ? detectChanges(rate_schedule) : rate_schedule;
    }

    public static billingItemstoPayload(itemTypeId: number, billingItems: S25ItemI[]) {
        let rate_schedule: any = { event_type: [] };
        billingItems = S25Util.array.forceArray(billingItems);
        billingItems.forEach((item) => {
            let itemType = rateTypeMap[itemTypeId];
            if (S25Util.parseInt(itemTypeId) === 1) {
                rate_schedule.event_type.push({
                    type_id: item.itemId,
                    type_name: item.itemName,
                    status: item.status,
                });
            } else {
                rate_schedule[itemType].push({
                    [itemType + "_id"]: item.itemId,
                    [itemType + "_name"]: item.itemName,
                    status: item.status,
                });
            }
        });

        return rate_schedule;
    }

    public static getItemTypeName(itemTypeId: number | string) {
        itemTypeId = S25Util.parseInt(itemTypeId);
        let itemType: any;
        switch (itemTypeId) {
            case 1:
                itemType = { itemId: 1, itemTypeCode: "eventTypes", itemTypeName: "Event Types" };
                break;
            case 2:
                itemType = { itemId: 2, itemTypeCode: "eventRequirements", itemTypeName: "Requirements" };
                break;
            case 3:
                itemType = { itemId: 3, itemTypeCode: "locations", itemTypeName: "Locations" };
                break;
            case 4:
                itemType = { itemId: 4, itemTypeCode: "resources", itemTypeName: "Resources" };
                break;
        }
        return itemType;
    }
}

/*
removes any unchanged nodes from a WS payload.
Prevents validation trip up on parts of the rate schedule that were not changed and were grandfathered in.
 */
function detectChanges(payload: RateSchedulePayload.PayloadI): PayloadI {
    let minPayload: RateSchedulePayload.PayloadI = {} as RateSchedulePayload.PayloadI;
    S25Util.copy(minPayload, payload);

    if (minPayload.status === "new") {
        // S25Util.setOnObjectDeep(minPayload, "status", "new", [], true);
        minPayload.event_type.forEach((t) => (t.status = "new"));
        minPayload.resource.forEach((t) => (t.status = "new"));
        minPayload.service.forEach((t) => (t.status = "new"));
        minPayload.space.forEach((t) => (t.status = "new"));
        minPayload.tax.forEach((t) => (t.status = "new"));
        minPayload.price_sheet.forEach((t) => {
            t.status = "new";
            t.rate_formula.forEach((f) => (f.status = "new"));
        });
    }

    minPayload.event_type = minPayload.event_type?.filter((type) => S25Util.isStatusChanged(type));
    minPayload.resource = minPayload.resource?.filter((item) => S25Util.isStatusChanged(item));
    minPayload.service = minPayload.service?.filter((item) => S25Util.isStatusChanged(item));
    minPayload.space = minPayload.space?.filter((item) => S25Util.isStatusChanged(item));
    minPayload.tax = minPayload.tax?.filter((item) => S25Util.isStatusChanged(item));

    //price_sheet should be included if it OR one of its children is changed
    let sheets = minPayload.price_sheet?.map((sheet) => {
        let changedSheet = S25Util.deepCopy(sheet);
        if (sheet.status === "new") {
            changedSheet.rate_formula.forEach((form) => (form.status = "new"));
        } else {
            changedSheet.rate_formula = sheet.rate_formula.filter((form) => S25Util.isStatusChanged(form));
            //status should be set when there are changed rate_formulas OR details are changed
            if (changedSheet.rate_formula.length > 0 && !S25Util.isStatusChanged(changedSheet)) {
                changedSheet.status = "mod";
            }
        }
        return changedSheet;
    });
    minPayload.price_sheet = sheets.filter((sheet) => S25Util.isStatusChanged(sheet));

    //set status to mod if there is a change in the document and status is not already changed
    if (
        !S25Util.isStatusChanged(minPayload) &&
        (minPayload.event_type?.length ||
            minPayload.resource?.length ||
            minPayload.service?.length ||
            minPayload.space?.length ||
            minPayload.tax?.length ||
            minPayload.price_sheet?.length)
    ) {
        minPayload.status = "mod";
    }

    return minPayload;
}
