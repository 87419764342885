import { Cache } from "../decorators/cache.decorator";
import { DataAccess } from "../dataaccess/data.access";
import { Timeout } from "../decorators/timeout.decorator";
import { jSith } from "../util/jquery-replacement";
import { S25Util } from "../util/s25-util";
import { RsvdetailServiceUtil } from "../modules/reservation/detail/service/rsrv.detail.service";

export class ReservationService {
    @Timeout
    public static getReservations(
        itemTypeId: number,
        itemId: number,
        startDt: Date | string,
        endDt: Date | string,
        includes: any,
    ) {
        includes = S25Util.array.forceArray(includes);
        startDt = S25Util.date.toS25ISODateTimeStr(startDt);
        endDt = S25Util.date.toS25ISODateTimeStr(endDt);
        let baseUrl = itemTypeId === 4 ? "/rm_reservations.json?space_id=" : "/rs_reservations.json?resource_id=";
        let url = baseUrl + itemId + "&start_dt=" + startDt + "&end_dt=" + endDt;
        let includesString = includes ? "&include=" + includes.join("+") : "";

        return DataAccess.get(DataAccess.injectCaller(url + includesString, "ReservationService.getReservations")).then(
            function (data) {
                data = data && S25Util.prettifyJson(data);
                //strip timezone information off of reservations as they should already have the date/times adjusted for us
                var reservations =
                    data &&
                    ((data.reservations && data.reservations.reservation) ||
                        (data.space_reservations && data.space_reservations.space_reservation) ||
                        (data.resource_reservations && data.resource_reservations.resource_reservation));
                reservations = S25Util.array.forceArray(reservations);
                jSith.forEach(reservations, function (_, rsrv) {
                    rsrv.reservation_start_dt = S25Util.date.dropTZString(rsrv.reservation_start_dt);
                    rsrv.reservation_end_dt = S25Util.date.dropTZString(rsrv.reservation_end_dt);
                    if (rsrv.event) {
                        rsrv.event.pre_event_dt = S25Util.date.dropTZString(rsrv.event.pre_event_dt);
                        rsrv.event.post_event_dt = S25Util.date.dropTZString(rsrv.event.post_event_dt);
                    }
                });
                return data;
            },
        );
    }

    //public static getReservationsByProfile(profIds: number[], scope: any) {
    //return DataAccess.get(
    //    DataAccess.injectCaller("/reservations.json?reservation_id=" + rsrvId + (scope ? "&scope=" + scope : ""),
    //         'ReservationService.getSingleReservation')
    // ).then(function(data) {
    //     let json = S25Util.prettifyJson(data, null, {space_reservation: true, resource_reservation: true});
    //     return json && json.reservations && json.reservations.reservation;
    // });
    //}

    public static generateReservationIds(quantity: number): Promise<{
        kind: string;
        updated: string;
        idSet: Array<{ row_num: number; rsrvId: number }>;
    }> {
        return DataAccess.post(
            DataAccess.injectCaller(
                "/micro/sequence/generate.json?element=reservation&quantity=" + quantity,
                "ReservationService.generateReservationIds",
            ),
            {},
        ).then(function (resp) {
            //items[0] should have a idset object array
            return resp &&
                resp.content &&
                resp.content.data &&
                resp.content.data.items &&
                resp.content.data.items.length > 0
                ? resp.content.data.items[0]
                : {};
        });
    }

    public static getEventReservations(eventId: number): Promise<EventReservation[]> {
        //must send a start and end to return reservations, we just want all for event so adding ridiculous range
        let baseUrl = "/reservations.json?start_dt=1970-01-01&end_dt=3000-01-01&event_id=";
        let url = baseUrl + eventId;
        return DataAccess.get(DataAccess.injectCaller(url, "ReservationService.getEventReservations")).then(
            function (data) {
                data = data && S25Util.prettifyJson(data);
                let occurrences: any = [];
                let occStatuses: any = {
                    1: "Active",
                    99: "Cancelled",
                    2: "Exception",
                    3: "Warning",
                    4: "Active",
                };
                occurrences = data && data.reservations && data.reservations.reservation;
                occurrences = S25Util.array.forceArray(occurrences);
                for (let o of occurrences) {
                    if (o.reservation_start_dt && o.reservation_end_dt) {
                        let stDate = new Date(o.reservation_start_dt);
                        let endDt = new Date(o.reservation_end_dt);
                        o.date = stDate.toDateString();
                        o.times = S25Util.date.toS25ISOTimeStr(stDate) + " - " + S25Util.date.toS25ISOTimeStr(endDt);
                        o.dateTime = o.date + " " + o.times;
                    } else {
                        o.date = "Unspecified";
                        o.times = "Unspecified";
                    }
                    o.stateName = o.reservation_state ? occStatuses[o.reservation_state] : "Unknown";
                    if (o.space_reservation) {
                        o.space_reservation = S25Util.array.forceArray(o.space_reservation);
                        o.locations = "";
                        for (let i = 0; i < o.space_reservation.length; i++) {
                            let sp = o.space_reservation[i]; //TODO: check system conf for formal vs name
                            o.locations = o.locations + sp.space_name + ", ";
                        }
                        o.locations = o.locations.slice(0, -2);
                    }
                    if (o.resource_reservation) {
                        o.resource_reservation = S25Util.array.forceArray(o.resource_reservation);
                        o.resources = "";
                        for (let i = 0; i < o.resource_reservation.length; i++) {
                            let rs = o.resource_reservation[i];
                            o.resources = o.resources + rs.resource_name + ", ";
                        }
                        o.resources = o.resources.slice(0, -2);
                    }
                }
                return occurrences;
            },
        );
    }

    public static getLocationReservations(itemId: number, startDt: Date, endDt: Date, includes: any) {
        return ReservationService.getReservations(4, itemId, startDt, endDt, includes);
    }

    public static getResourceReservations(itemId: number, startDt: Date, endDt: Date, includes: any) {
        return ReservationService.getReservations(6, itemId, startDt, endDt, includes);
    }

    @Cache({ targetName: "ReservationService" })
    public static getRsvdetail(rsvId: number) {
        return DataAccess.get(
            DataAccess.injectCaller(
                "/reservation/detail/rsvdetail.json?rsvId=" + rsvId,
                "ReservationService.getRsvdetail",
            ),
        ).then(function (data) {
            var rsv = data.rsvdetail.rsv;
            rsv &&
                S25Util.extend(
                    rsv,
                    {
                        startDt: S25Util.date.parse(rsv.startDt),
                        endDt: S25Util.date.parse(rsv.endDt),
                        preDt: S25Util.date.parse(rsv.preDt),
                        postDt: S25Util.date.parse(rsv.postDt),
                        setupDt: S25Util.date.parse(rsv.setupDt),
                        takedownDt: S25Util.date.parse(rsv.takedownDt),
                    },
                    // expand by default, but only when isExpandable=true;
                    // otherwise must set isCollapsed=true, or cancelled rsv not shown
                    { isCollapsed: RsvdetailServiceUtil.isRsvExpandable(rsv) ? false : true },
                );
            return data;
        });
    }

    @Timeout
    public static getSingleReservation(rsrvId: number, scope: any) {
        return DataAccess.get(
            DataAccess.injectCaller(
                "/reservations.json?reservation_id=" + rsrvId + (scope ? "&scope=" + scope : ""),
                "ReservationService.getSingleReservation",
            ),
        ).then(function (data) {
            let json = S25Util.prettifyJson(data, null, { space_reservation: true, resource_reservation: true });
            return json && json.reservations && json.reservations.reservation;
        });
    }

    @Timeout
    public static getReservationState(rsrvId: number) {
        return ReservationService.getSingleReservation(rsrvId, "minimal").then(function (data) {
            return parseInt(S25Util.propertyGet(data, "reservation_state"));
        });
    }
}

export interface EventReservation {
    date: string;
    dateTime: string;
    event_end_dt: string;
    event_id: number;
    event_locator: string;
    event_name: string;
    event_start_dt: string;
    event_title: string;
    event_type_class: string;
    event_type_name: string;
    expected_count: string;
    last_mod_dt: string;
    last_mod_user: string;
    locations: string;
    organization_id: number;
    organization_name: string;
    post_event_dt: string;
    pre_event_dt: string;
    profile_description: string;
    profile_id: number;
    profile_name: string;
    registered_count: string;
    registration_label: string;
    registration_url: string;
    reservation_comment_id: string;
    reservation_comments: string;
    reservation_end_dt: string;
    reservation_id: number;
    reservation_start_dt: string;
    reservation_state: number;
    reservation_state_name: string;
    space_reservation: EventSpaceReservation[];
    resource_reservation: EventResourceReservation[];
    resources: string;
    stateName: string;
    times: string;
}

export interface EventObjectReservation {
    actual_count?: number;
    default_layout_capacity?: number;
    event_id?: number;
    event_name?: string;
    expected_count?: number;
    disabled?: boolean;
    formal_name?: string;
    last_mod_dt?: string;
    last_mod_user?: string;
    layout_id?: number;
    layout_name: string;
    organization_id?: string | number;
    organization_name?: string;
    profile_code?: string;
    profile_id?: string | number;
    profile_name?: string;
    rating?: string;
    rsrv_end_dt?: string;
    rsrv_start_dt?: string;
    selected_layout_capacity?: number;
    state?: string;
    state_name?: string;
}

export interface EventSpaceReservation extends EventObjectReservation {
    shared?: string;
    space_id?: string | number;
    space_instruction_id?: string;
    space_instructions?: string;
    space_name?: string;
}

export interface EventResourceReservation extends EventObjectReservation {
    resource_id?: string | number;
    resource_name?: string;
    stock_level?: number;
}
