import { DataAccess } from "../dataaccess/data.access";
import { S25Util } from "../util/s25-util";
import { Proto } from "../pojo/Proto";
import { LooseAutocomplete } from "../pojo/Util";
import { Timeout } from "../decorators/timeout.decorator";
import { Cache } from "../decorators/cache.decorator";
import { UserprefService } from "./userpref.service";
import { ContactService } from "./contact.service";
import { PreferenceService } from "./preference.service";
import { S25Const } from "../util/s25-const";
import { EventService } from "./event.service";
import { DocumentService } from "./document.service";
import LowerCharBoolean = Proto.LowerCharBoolean;
import EmailString = Proto.EmailString;
import HTMLString = Proto.HTMLString;
import URLString = Proto.URLString;
import OffsetISODateString = Proto.OffsetISODateString;
import XMLString = Proto.XMLString;
import NumericalString = Proto.NumericalString;
import { Doc } from "../modules/system-settings/document-management/s25.document.const";
import { Report } from "../pojo/Report";
import Reports = Report.Reports;
import ObjectType = Report.ObjectType;
import { EmailClass, EmailReport, EmailService } from "./email.service";

export class ReportService {
    public static NoDetails: HTMLString = "<i>No Details Available</i>";

    @Timeout
    @Cache({ immutable: true, targetName: "ReportService" })
    public static async getReports(): Promise<Report.Object[]> {
        const data = await DataAccess.get<{
            reports: { engine: LooseAutocomplete<"accl">; pubdate: OffsetISODateString; report: Report.Object[] };
        }>(DataAccess.injectCaller(`/reports.json`, "ReportsService.getReports"));
        return data?.reports?.report?.filter(hasEngine);
    }

    @Timeout
    @Cache({ immutable: true, targetName: "ReportService" })
    public static async getReport(id: number): Promise<Report.Object> {
        const url = `/reports.json?report_id=${id}`;
        const data = await DataAccess.get<{
            reports: { engine: LooseAutocomplete<"accl">; pubdate: OffsetISODateString; report: Report.Object };
        }>(DataAccess.injectCaller(url, "ReportsService.getReport"));
        return data?.reports?.report;
    }

    @Timeout
    public static async getReportsByType(type: ReportType): Promise<Report.Object[]> {
        const reports = await ReportService.getReports();
        return reports?.filter(isType(type));
    }

    @Timeout
    public static async getReportMeta(id: number): Promise<Report.Meta> {
        const url = DataAccess.injectCaller(`/report.run?report_id=${id}`, "ReportService.getReportMeta");
        const data = await DataAccess.post<{ reports: { engine: LooseAutocomplete<"accl">; report: Report.Meta } }>(
            url,
            undefined,
            { Accept: "application/json, text/plain, */*" },
            "json",
        );
        return data?.reports?.report;
    }

    @Timeout
    public static async getFavoriteReports(): Promise<Report.Object[]> {
        const reports = await ReportService.getReports();
        return reports.filter((report) => report.favorite === "T");
    }

    @Timeout
    @Cache({ immutable: true, targetName: "ReportService" })
    public static async getReportHelpHTML(id: number): Promise<HTMLString> {
        const url = DataAccess.injectCaller(`/report.help?report_id=${id}`, "ReportService.getReportHelpHTML");
        const promise = DataAccess.get<HTMLString>(url);
        const [html, error] = await S25Util.Maybe(promise);

        if (error) {
            console.error(error);
            return ReportService.NoDetails;
        }
        if (!html) return ReportService.NoDetails;

        return /<body>([\s\S]*)<\/body>/gim.exec(html)?.[1] ?? ReportService.NoDetails;
    }

    public static getReportSampleUrl(id: number): URLString {
        const url = DataAccess.injectCaller(`/report.sample?report_id=${id}`, "ReportService.getReportSampleUrl");
        return DataAccess.getUrl(url);
    }

    @Timeout
    public static async getReportTemplate(id: number): Promise<Report.Meta | { message: string }> {
        const [data, error] = await S25Util.Maybe(ReportService.getReportMeta(id));

        if (error) {
            if (parseInt(error.status) === 403) return { message: "No report is available." };
            return;
        }

        return data;
    }

    @Timeout
    public static async emailReport(
        id: number,
        runId: number | NumericalString,
        name: string,
        format: NumericalString | number,
        recipientList: any,
        message: string = "",
    ) {
        const email = {
            email: {
                mail: {
                    subject: name,
                    recipient: {
                        to: recipientList,
                    },
                    body: {
                        text: `Please find attached the ${name} you requested. ${message}`,
                    },
                },
            },
        };

        const formatUrl = format ? `&report_format=${format}` : "";
        const url = DataAccess.injectCaller(
            `/report.mail?report_id=${id}&report_run_id=${runId}${formatUrl}`,
            "ReportService.emailReport",
        );
        return DataAccess.post(url, email);
    }

    /**
     * Email a payment report - must be DM type 11 Invoice (Payment)
     * @param eventId
     * @param evBillId
     * @param report
     * @param email
     */
    public static async emailPaymentReport(
        eventId: number,
        evBillId: number,
        email: EmailClass,
        report?: Report.SimpleObject,
    ) {
        if (report) {
            let emailRpt: EmailReport = {
                itemID: undefined,
                objString: "",
                fromTemplate: false,
                rpt_id: report.rpt_id,
                rpt_engine: report.rpt_engine,
                isString: false,
                params: {
                    num_parm1: eventId,
                    num_parm2: evBillId.toString(),
                    true_value: "T",
                    false_value: "F",
                },
            };

            if (report.document_id) {
                const documentRunId = await DocumentService.getEventDocumentRunId(report.document_id, eventId);
                if (!documentRunId) throw new Error("No documentRunId could be generated");

                emailRpt.itemID = documentRunId;
                emailRpt.params.num_parm3 = documentRunId.toString();
            }

            email.reports = [emailRpt] as EmailReport[];
        }

        return EmailService.sendEmailFromClass(email, eventId, S25Const.itemName2Id["event"]);
    }

    /**
     *
     * @param eventId
     * @param evBillId - used for billing a single pricing set, if undefined bill the full event
     * @param report - Optional report to print the invoice as - if no report provided use default invoice for the event
     */
    @Timeout
    public static async invoiceReport(eventId: number, evBillId: number, report?: Report.SimpleObject) {
        const reports = await EventService.getEventReports(eventId);

        if (evBillId) {
            const defaultPaymentReport = reports.find((report) => report.rpt_use === Report.Use.Payment);
            report ??= defaultPaymentReport;

            const context: Report.Context = {
                num_parm1: eventId,
                num_parm2: evBillId.toString(),
            };
            if (report.document_id) {
                context.documentRunId = await DocumentService.getEventDocumentRunId(report.document_id, eventId);
            }

            return ReportService.runReportRequest(report, context, 2, null, null, null);
        }

        const defaultInvoiceReport = reports.find((report) => report.rpt_use === Report.Use.Invoice);
        report ??= defaultInvoiceReport;

        if (!report) {
            alert("This report is not available for this event. Please contact your administrator.");
        } else {
            let documentRunId: number = null;
            // if DM report, need documentRunId
            if (report.rpt_engine === "DM") {
                const [response, error]: any = await S25Util.Maybe(ReportService.getReportTemplate(report.rpt_id));
                // Handle errors
                if (error) {
                    S25Util.showError(error);
                    return;
                }
                if (response?.document_id) {
                    const document = await DocumentService.getDocument(response?.document_id);
                    const { header_text, body_text, footer_text } = document;
                    documentRunId = await DocumentService.getEventDocumentRunIdFromText(
                        header_text,
                        body_text,
                        footer_text,
                        null,
                        eventId,
                    );
                }
            }

            return ReportService.runReportRequest(
                report,
                {
                    documentRunId: documentRunId,
                    num_parm1: eventId.toString(),
                },
                2,
                null,
                null,
                null,
            ); //formats: 1:html, 2:pdf, 3:text, 4:excel, 6:rtf, yes there is no 5... see s25-report
        }
    }

    @Timeout
    public static async runReportRequest(
        report: Report.SimpleObject,
        context: Report.Context,
        format: NumericalString | number,
        delivery: "email" | "print" | "other",
        otherRecipients: string,
        otherMessage: string,
    ) {
        delivery ??= "print";
        const [data, error] = await S25Util.Maybe(
            ReportService.putReportRequest(report, context, format, delivery, otherRecipients, otherMessage),
        );

        if (error) {
            if (parseInt(error.status) === 403) return { message: "No report is available." };
            return;
        }

        if (typeof data === "object") {
            alert(data.message);
        }

        // return data;
    }

    @Timeout
    public static getReportResults(key: string) {
        return DataAccess.get(DataAccess.getUrl(`/results?request=${key}`), null, null, "blob", "response");
    }

    public static async waitForReportResults(key: string, initialWait = 0, interval = 1000) {
        if (initialWait) await S25Util.delay(initialWait);
        while (true) {
            const resp = await ReportService.getReportResults(key);
            if (resp.status === 202) await S25Util.delay(interval);
            else return resp;
        }
    }

    public static _putReportRequest(url: string, template: Report.Meta) {
        return DataAccess.put<{
            results: {
                info: { msg_id: string; msg: string };
                pubdate: OffsetISODateString;
                engine: LooseAutocomplete<"sws">;
            };
        }>(url, { reports: { report: template } }, { "Content-Type": "application/json" }, "json");
    }

    @Timeout
    public static async putReportRequest(
        report: Report.SimpleObject & { report_id?: number; report_engine?: Report.Engine; isAsync?: boolean },
        context: Report.Context,
        format?: NumericalString | number,
        delivery?: "email" | "print" | "other",
        otherRecipients?: any,
        otherMessage?: string,
        customFilename?: string,
        devMode: boolean = false,
    ): Promise<boolean | { message: string }> {
        const id = report.rpt_id || report.report_id;
        const engine = report.rpt_engine || report.report_engine;
        const isAsync = report.isAsync;

        const [response, error] = await S25Util.Maybe(
            Promise.all([UserprefService.getSessionId(), ReportService.getReportTemplate(id)]),
        );

        // Handle errors
        if (error) {
            S25Util.showError(error);
            return;
        }
        const [sessionId, template] = response;
        if (!template) return;
        if ("message" in template) return { message: template.message };
        if (!template?.report_run) return;

        // Process data
        const runId = template.report_run[0].report_run_id;
        const name = template.report_name || template.rpt_name;
        const reportFilename = template.report_filename;

        let specialItemId: number; //used to set &itemid=... on report.run calls to inject special ids into reports (contract / document run id, for example)
        if (S25Util.isInt(context)) template.report_run.num_parm1 = context;
        else if (S25Util.isObject(context)) {
            for (let key in context) {
                if (!context.hasOwnProperty(key)) continue;
                if (key === "documentRunId") specialItemId = parseInt(context[key]);
                else template.report_run[0][key as keyof Report.Run] = context[key as keyof Report.Context];
            }
        }

        // Save report
        const url = DataAccess.injectCaller(`/report.run?report_id=${id}`, "ReportService.putReportRequest");
        const saveData = await ReportService._putReportRequest(url, template);
        if (!saveData?.results?.info) return;

        // Unsuccessful
        if (saveData.results.info.msg !== "Report successfully saved") {
            // console.log("save", JSON.stringify(saveData));
            if (JSON.stringify(saveData).includes("Unable to lock report")) {
                return {
                    message:
                        "The report could not be run because the report template is currently being edited. Please try again shortly.",
                };
            }
            return {
                message: "We encountered an error trying to run this report. Please try again shortly.",
            };
        }

        // Success
        if (delivery === "email") {
            const currentEmail: EmailString = await ContactService.getCurrentEmail();
            if (!currentEmail) return;
            return ReportService.emailReport(id, runId, name, format, currentEmail, "");
        } else if (delivery === "other") {
            return ReportService.emailReport(id, runId, name, format, otherRecipients, otherMessage);
        } else if (engine === "WS" || engine === "DM") {
            const data = await ReportService.getWSReportResponse(
                id,
                runId,
                false,
                specialItemId,
                sessionId,
                isAsync,
                devMode,
            );
            if (!data) return;
            if (data.results?.result) {
                const resp = await ReportService.waitForReportResults(data.results.result.key, 3000, 1000);
                const blob = await resp.body;
                const filename = resp.headers.get("Content-Disposition").split("filename=")[1];
                ReportService.download(customFilename || filename, blob, blob.type);
                return;
            }
            if (isAsync && data.headers && S25Util.isFunction(data.headers)) {
                const contentDisposition = data.headers("Content-Disposition");
                if (!contentDisposition?.includes("attachment")) return;
                const blobResp = await ReportService.getWSReportResponse(
                    id,
                    runId,
                    true,
                    specialItemId,
                    sessionId,
                    isAsync,
                );
                const type = blobResp.headers("Content-Type");
                let filename = getFilename(blobResp.headers);
                ReportService.download(customFilename || filename, blobResp.data, type);
            } else if (data?.headers && data?.data) {
                const type = data.headers("Content-Type");
                const filename = getFilename(data.headers);
                ReportService.download(customFilename || filename, data.data, type);
            } else if (data && !data.headers) {
                const type = data.reports.report.mime_type;
                const filenameTemp = data.reports.report.content_disposition;
                // no header let set file name here instead of getFilename Funcation
                const parts = filenameTemp.split("filename=");
                let filename = parts.length === 1 ? parts[0] : parts.length > 1 ? parts[1] : "file.pdf";
                filename = filename.replace(/"/g, "");
                ReportService.download(customFilename || filename, data.reports.report, type);
            } else {
                alert(
                    "There was an error running your report, please try again. If the issue persists, contact your system administrator.",
                );
                return;
            }
        } else if (engine === "JR" && reportFilename) {
            const preferences = await PreferenceService.getPreferences(
                ["config_jrserver_url", "config_jrserver_inst"],
                "S",
            );
            // if jr server url is an internal url, we need to switch to the proxied one... (VOODOO-4797)
            if (preferences.config_jrserver_url.value?.includes(":8080")) {
                preferences.config_jrserver_url.value = "https://reports.collegenet.com/";
            }
            let jrServer: URLString = preferences.config_jrserver_url.value + "jinfonet/runReport.jsp";
            const jrInstance = preferences.config_jrserver_inst.value;
            const jrDir = id > 0 ? `/25live/${jrInstance}/` : "/25live/standard/";
            const jrCatalog = "25LIVE_WS.cat";

            const isLoggedIn = await UserprefService.getLoggedIn();
            if (!isLoggedIn) return;

            const email = await ContactService.getCurrentEmail();
            let webserviceBase: URLString = S25Const.webservicesBaseUrl as string;
            if (
                !webserviceBase.includes("25live.collegenet.com") &&
                !["devel", "fedevel"].includes(S25Const.instanceId as string)
            ) {
                //this is not a dev instance but is running off a dev domain
                //send jreport the prod version of this instance's WS url
                webserviceBase = `https://25live.collegenet.com/25live/data/${S25Const.instanceId}/run`;
            }

            //avoid external proxy -- internal call for internal use: ANG-2094
            webserviceBase = webserviceBase.replace(
                "https://25live.collegenet.com",
                "http://25live-web.collegenet.com:8080/25saas",
            );
            webserviceBase = webserviceBase.replace("25live.collegenet.com", "25live-web.collegenet.com:8080/25saas");

            jrServer +=
                `?jrs.cmd=jrs.web_vw&jrs.result_type=${format}&jrs.report=${
                    jrDir + (customFilename || reportFilename).replace(".xsl", ".cls")
                }` +
                `&jrs.catalog=${
                    jrDir + jrCatalog
                }&jrs.param$P_REPORT_RUN=${webserviceBase}/report.xml%3Freport_id=${id}` +
                `%26report_run_id=${runId}%26otransform=jreport_run.xsl%26session_id=${sessionId}&instance_id=${jrInstance}&CustomOffset=true`;

            switch (parseInt(format as NumericalString)) {
                case 1:
                    jrServer += "&jrs.is_multi_files=false";
                    break;
                case 3:
                    jrServer += "&jrs.hasHeadFoot=true&jrs.txt_windows=true";
                    break;
                case 4:
                    jrServer += "&jrs.excel_format=0&jrs.excel_layout=false";
                    break;
            }

            if (email) {
                const warning = encodeURIComponent(
                    "This report is taking longer than 2 minutes to run. Therefore, the report will be emailed when finished.",
                );
                const encodedEmail = encodeURIComponent(email);
                const encodedName = encodeURIComponent(name);
                const message = encodeURIComponent("Please find attached the " + encodedName + " you requested");
                jrServer += `&jrs.timeout_sendmail_message=${warning}&jrs.timeout_send_email=true&jrs.report_timeout=120&jrs.mailto=${encodedEmail}&jrs.mailfrom=${encodedEmail}&jrs.mailsubject=${encodedName}&jrs.mailcomments=${message}`;
            }
            return ReportService.spawnJRReportWindowNative(name, runId, jrServer);
        }
    }

    public static download(filename: string, data: BlobPart, contentType: string) {
        const blob = new Blob([data], { type: contentType });
        const objectUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = objectUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(objectUrl);
    }

    @Timeout
    public static async getWSReportResponse(
        id: number,
        runId: number | NumericalString,
        asBlob: boolean,
        specialItemId: number,
        sessionId: string,
        isAsync: boolean,
        devMode: boolean = false,
    ) {
        const asyncChar: LowerCharBoolean = isAsync || isAsync === undefined ? "t" : "f";
        const responseType = asBlob ? "blob" : null;
        const special = specialItemId ? `&itemid=${specialItemId}` : "";
        const session = sessionId ? `&session_id=${sessionId}` : "";
        const dev = devMode ? "&dev_mode=T" : "";
        // There is no way to get a JSON response from this endpoint
        const url = `/report.run?report_id=${id}&report_run_id=${runId}&async=${asyncChar}${special}${session}${dev}`;
        const data = await DataAccess.get<XMLString>(
            DataAccess.injectCaller(url, "ReportService.getWSReportResponse"),
            null,
            null,
            responseType,
        );
        return S25Util.prettyConv<{ results: { result: { key: string; message: string } }; [key: string]: any }>(data);
    }

    public static spawnWsReportWindow(name: string, key: string) {
        const title = `25Live Report: ${name} -- ${key}`;
        const win = window.open("", title, "");

        if (!win?.document) {
            alert("Unable to open new window for report download. Please enable pop-ups in the browser and try again.");
            return;
        }

        win.document.title = title;
        win.ReportUrl = DataAccess.getUrl(`/results?request=${key}`);
        win.RptUrl = win.ReportUrl + "&no202=T";
        win.reportName = win.ReportUrl;
        win.reportKey = win.ReportUrl;

        const script = win.document.createElement("script");
        script.setAttribute("type", "text/javascript");
        script.text = `
            function reloadIFrame () {
                if (document.getElementById('reportFrame')) {
                    var RptFrame = document.getElementById('reportFrame');
                    var RptFrameDocument;

                    if (RptFrame.contentDocument) {
                        RptFrameDocument = RptFrame.contentDocument;
                    } else if (RptFrame.contentWindow) {
                        RptFrameDocument = RptFrame.contentWindow.document;
                    } else {
                        RptFrameDocument = RptFrame.document;
                    }

                    if (RptFrameDocument.getElementsByTagName('r25:error_msg').length > 0) {}
                    else if (RptFrameDocument.getElementsByTagName('html').length > 0 && RptFrameDocument.getElementsByTagName('div').length === 0) {}
                    else {
                        RptFrame.contentWindow.location.href = 'about:blank';
                        window.setTimeout(function() { document.getElementById('reportFrame').contentWindow.location.href = window.RptUrl; }, 750);
                        window.setTimeout(window.reloadIFrame, 30000);
                    }
                } else {
                    window.setTimeout(window.reloadIFrame, 3000);
                }
            }

            var started = false;
            function startIFrame () {
                if(!started) {
                    started = true;
                    window.setTimeout(reloadIFrame, 3000);
                }
            }

            function refreshWindow () {
                window.opener.RefreshWSReport(window.reportName, window.reportKey);
            }
            `;

        win.document.body.innerHTML = `
            <div>
                <div class="layout" id="Layout">
                    <div class="header">
                        <div class="logo"></div>
                    </div>
                    <div [style]="display: flex;">
                        <div style="padding:25px;">
                            <b style="font-size: 120%;">Preparing...</b>
                            <br/>
                            <br/>
                            <span>Please wait...<span>
                        </div>
                        <div style="padding:25px">
                            <span>If the report is not loading after 30 seconds,</span>
                            <a href="javascript:refreshWindow()">click here to refresh</a>
                        </div>
                        <div style="padding:25px">
                            <a href="javascript:self.close()">Close Window</a>
                        </div>
                    </div>
                    <iframe id="reportFrame" src="${win.ReportUrl}" style="width:1px; height:1px; position:absolute; top:-10px; left:-10px; display:none;" onload="startIFrame()"></iframe>
                </div>
            </div>
        `;
        win.document.head.appendChild(script);
        return true;
    }

    public static spawnJRReportWindowNative(name: string, runId: number | NumericalString, server: URLString) {
        const title = `25Live Report: ${name} -- ${runId}`;
        const win = window.open("", title, "");

        if (!win?.document) {
            alert("Unable to open new window for report download. Please enable pop-ups in the browser and try again.");
            return;
        }

        win.document.title = title;
        win.report = server;

        const script = win.document.createElement("script");
        script.setAttribute("type", "text/javascript");
        script.text = "location.href = window.report;";

        win.document.body.innerHTML = `
            <div>
                <div class="layout" id="Layout">
                    <div class="header">
                        <div class="logo"></div>
                    </div>
                    <div [style]="display: flex;">
                        <div style="padding:25px;">
                            <b style="font-size: 120%;">Preparing...</b>
                            <br/>
                            <br/>
                            <span>Please wait...</span>
                        </div>
                        <div style="padding:25px">
                            <a href="javascript:self.close()">Close</a>
                        </div>
                    </div>
                </div>
            </div>`;
        win.document.head.appendChild(script);
        return true;
    }

    public static async newReportId() {
        const url = DataAccess.injectCaller(`/report.json`, "ReportService.newReportId");
        const data = await DataAccess.post<{ reports: { report: Report.Object } }>(url);
        return data.reports.report.report_id as any as number;
    }

    public static updateReport(report: Report.Meta) {
        const url = DataAccess.injectCaller(`/report.json?report_id=${report.report_id}`, "ReportService.updateReport");
        return DataAccess.put(url, { reports: { report } });
    }

    public static deleteReport(reportId: number) {
        const url = DataAccess.injectCaller(`/report.json?report_id=${reportId}`, "ReportService.deleteReport");
        return DataAccess.delete(url);
    }

    @Timeout
    @Cache({ immutable: true, targetName: "ReportService" })
    public static async getDefaultDocumentFileNames() {
        const documentReportIds = Doc.scopeOptions.map((scope) => scope.reportId);
        const url = DataAccess.injectCaller(
            `/reports.json?report_id=${documentReportIds.join(",")}`,
            "ReportService.getDefaultDocumentFileNames",
        );

        let data = await DataAccess.get(url);

        if (data?.reports?.report) {
            data = S25Util.array.forceArray(data?.reports?.report);
            return S25Util.fromEntries(
                data.map((report: Report.Object) => [
                    report.report_id,
                    report.content_disposition?.replace(/^[^=]+=/, "") || "",
                ]),
            ) as Record<Doc.ScopeReportId, string>;
        } else {
            return {} as Record<Doc.ScopeReportId, string>; // no reports
        }
    }

    public static normalizeReport(reports: Report.Object[], sortByObjectType?: boolean) {
        for (const report of reports) {
            // Set DM report name more meaningful
            const skip = new Set<number>([
                Reports.EventShell.id,
                Reports.ReservationShell.id,
                Reports.OrganizationShell.id,
                Reports.PaymentShell.id,
            ]);
            if (report.report_engine === "DM" && !skip.has(Number(report.report_id))) {
                switch (report.object_type) {
                    case ObjectType.EventDocument:
                        report.report_name = "Event Document: " + report.report_name;
                        break;
                    case ObjectType.PaymentDocument:
                        report.report_name = `Invoice Document: ${report.report_name}`;
                        break;
                    case ObjectType.ReservationDocument:
                        report.report_name = "Reservation Document: " + report.report_name;
                        break;
                    case ObjectType.OrganizationDocument:
                        report.report_name = "Organization Document: " + report.report_name;
                        break;
                }
            }

            report.itemId = report.report_id;
            report.itemName = report.report_name;
        }

        if (sortByObjectType) {
            const objectTypeOrder = [
                ObjectType.Event,
                ObjectType.EventDocument,
                ObjectType.OrganizationDocument,
                ObjectType.ReservationDocument,
            ];
            const order = new Map<number, number>(objectTypeOrder.map((id, i) => [id, i]));
            reports.sort((a: Report.Object, b: Report.Object) => {
                const aVal = Number(a.object_type);
                const bVal = Number(b.object_type);
                if (!order.has(aVal) || !order.has(bVal)) {
                    return aVal - bVal;
                }
                return order.get(aVal) - order.get(bVal);
            });
        }

        return reports;
    }

    public static async getQueryReports(type: ReportType) {
        const reports = await ReportService.getReportsByType(type);
        return reports.filter((report) => {
            switch (type) {
                case ReportType.Event:
                    if (report.object_type !== ObjectType.EventListingDocument) return false; // Only event listings
                    if (report.report_engine === "DM" && !report.document_id) return false; // Filter out shell report
                    return true;
                case ReportType.Location:
                    if (report.object_type !== ObjectType.LocationListingDocument) return false; // Only location listings
                    if (report.report_engine === "DM" && !report.document_id) return false; // Filter out shell report
                    return true;
                case ReportType.Resource:
                    if (report.object_type !== ObjectType.ResourceListingDocument) return false; // Only resource listings
                    if (report.report_engine === "DM" && !report.document_id) return false; // Filter out shell report
                    return true;
                case ReportType.Organization:
                    if (report.object_type !== ObjectType.OrganizationListingDocument) return false; // Only organization listings
                    if (report.report_engine === "DM" && !report.document_id) return false; // Filter out shell report
                    return true;
                default:
                    return false;
            }
        });
    }
}

function hasEngine(report: Report.Object) {
    return report.report_engine === "WS" || report.report_engine === "JR" || report.report_engine === "DM";
}

function isType(type: ReportType) {
    return (report: Report.Object): boolean => {
        const group = parseInt(report.report_group_id as string);
        const id = parseInt(report.report_id as string);
        switch (type) {
            case ReportType.Other:
                return ![-1, 1, 4, 8, 11].includes(group);
            case ReportType.Event:
                return [1, 8].includes(group) || id === -63;
            case ReportType.Location:
                return group === 4 && id !== -63;
            case ReportType.Resource:
                return group === 11;
            case ReportType.Organization:
                return group === 10 || group === 2;
        }
    };
}

function getFilename(headers: Function): string {
    const disposition = headers("Content-Disposition");
    const parts = disposition.split("filename=");
    let filename = parts.length === 1 ? parts[0] : parts.length > 1 ? parts[1] : "file.pdf";
    filename = filename.replace(/"/g, "");
    return filename;
}

export const enum ReportType {
    Other = 0,
    Event = 1,
    Organization = 2,
    Location = 4,
    Resource = 6,
}
