import { Timeout } from "../decorators/timeout.decorator";
import { jSith } from "../util/jquery-replacement";
import { S25Util } from "../util/s25-util";
import { S25Const } from "../util/s25-const";
import { SpaceService } from "./space.service";
import { ResourceService } from "./resource.service";
import { DataAccess } from "../dataaccess/data.access";

interface Relationship {
    relationshipId?: number;
    source: {
        itemId: number;
        itemName?: string;
    };
    target: {
        itemId: number;
        itemName?: string;
    };
    relnType: {
        itemId: number;
        itemName?: string;
    };
    services?: {
        get: any;
        put: any;
    };
    itemTypeId?: number;
    status?: string;
}

interface LocationNode {
    relationship_id: number;
    relationship_name?: string;
    related_space_id: number;
    related_space_name?: string;
    status?: string;
}

interface ResourceNode {
    relationship_id?: number; //like a reservationId
    relationship_type_id: number;
    relationship_type?: string;
    related_resource?: string; //name of target resource
    source_resource_id: number;
    target_resource_id: number;
    status?: string;
}

export class RelationshipService {
    public static objToNode(relnObjs: any[], itemTypeId: number) {
        //: Array<LocationNode | ResourceNode>{
        if (itemTypeId == 4) {
            relnObjs = S25Util.array.forceArray(relnObjs);
            if (relnObjs[0] && relnObjs[0].itemLabel) {
                //uses format from spdetail.json (space/detail/spdetail.json?space_id=3305)
                return relnObjs.map((item) => {
                    let node: LocationNode = {
                        relationship_id: null,
                        relationship_name: item.itemLabel,
                        related_space_id: item.itemId,
                        related_space_name: item.itemName,
                        status: "est",
                    };

                    let reln = S25Util.array.getByProp(S25Const.relationships[4], "itemName", item.itemLabel);
                    node.relationship_id = reln && reln.itemId;
                    return node;
                });
            } else if (relnObjs[0] && relnObjs[0].relnType) {
                return (
                    relnObjs &&
                    relnObjs.map((reln) => {
                        let node: LocationNode = {
                            relationship_id: reln.relnType && reln.relnType.itemId,
                            relationship_name: reln.relnType && reln.relnType.itemName,
                            related_space_id: reln.target && reln.target.itemId,
                            related_space_name: reln.target && reln.target.itemName,
                            status: reln.status,
                        };
                        return node;
                    })
                );
            }
        } else if (itemTypeId == 6) {
            return relnObjs.map((reln) => {
                return {
                    relationship_id: reln.relationshipId,
                    relationship_type_id: reln.relnType.itemId,
                    relationship_type: reln.relnType.itemName,
                    related_resource: reln.target.itemName,
                    source_resource_id: reln.source.itemId,
                    target_resource_id: reln.target.itemId,
                    status: reln.status,
                };
            });
        } else {
            return null;
        }
    }

    public static nodeToObj(
        relnNodes: Array<LocationNode | ResourceNode>,
        itemTypeId: number,
        sourceId: number,
    ): Array<Relationship> {
        if (!relnNodes) return null;
        if (itemTypeId == 4) {
            let locationNodes = relnNodes as LocationNode[];
            locationNodes = S25Util.array.forceArray(locationNodes);
            let objArr = locationNodes.map((reln) => {
                let obj = {
                    relnType: {
                        itemId: reln.relationship_id,
                        itemName: reln.relationship_name,
                    },
                    target: {
                        itemId: reln.related_space_id,
                        itemName: reln.related_space_name,
                    },
                    source: { itemId: sourceId },
                    status: reln.status,
                };
                return obj;
            });
            return objArr;
        } else if (itemTypeId == 6) {
            let resourceNodes = relnNodes as ResourceNode[];
            resourceNodes = S25Util.array.forceArray(resourceNodes);
            return resourceNodes.map((reln) => {
                return {
                    relationshipId: reln.relationship_id,
                    relnType: {
                        itemId: reln.relationship_type_id,
                        itemName: reln.relationship_type,
                    },
                    target: {
                        itemId: reln.target_resource_id,
                        itemName: reln.related_resource,
                    },
                    source: {
                        itemId: reln.source_resource_id as number,
                    },
                    status: reln.status,
                };
            });
        }
    }

    public static isEqual(reln1: Relationship, reln2: Relationship): boolean {
        if (
            (reln1 && reln1.source && reln1.target,
            reln1.relnType && reln2 && reln2.source && reln2.target,
            reln2.relnType)
        ) {
            return (
                reln1.source.itemId == reln2.source.itemId &&
                reln1.target.itemId == reln2.target.itemId &&
                reln1.relnType.itemId == reln2.relnType.itemId
            );
        }
        return false;
    }

    public static find(reln: Relationship, relns: Relationship[]): number {
        for (let i = 0; i < relns.length; i++) {
            if (RelationshipService.isEqual(reln, relns[i])) return i;
        }
        return -1;
    }

    public static reverseRelationship(reln: Relationship) {
        return {
            source: { itemId: reln.target && reln.target.itemId, itemName: reln.target && reln.target.itemName },
            relnType: {
                itemId: reln.relnType && reln.relnType.itemId,
                itemName: reln.relnType && reln.relnType.itemName,
            },
            target: { itemId: reln.source && reln.source.itemId, itemName: reln.source && reln.source.itemId },
            itemTypeId: reln.itemTypeId,
            status: reln.status,
        };
    }

    public static findAllBySource(itemId: number, relns: Relationship[]): number[] {
        let indexes = [];
        for (let i = 0; i < relns.length; i++) {
            if (relns[i].source.itemId === itemId) indexes.push(i);
        }
        return indexes;
    }

    @Timeout
    public static getRelationships(itemIds: number | number[], itemTypeId: number): Promise<Relationship[]> {
        itemIds = S25Util.array.forceArray(itemIds);
        let getService: any;
        if (itemTypeId == 4) {
            getService = SpaceService.getSpacesIncludes;
        } else if (itemTypeId == 6) {
            getService = ResourceService.getResourcesIncludes;
        } else {
            return null;
        }

        return getService(itemIds, ["relationships"]).then((resp: any) => {
            let retVal: any[] = [];
            resp.forEach((objNodes: any) => {
                let sourceId = objNodes.space_id || objNodes.resource_id;
                let arr = RelationshipService.nodeToObj(objNodes.relationship, itemTypeId, sourceId);
                S25Util.array.injectArray(retVal, retVal.length, arr);
            });
            return retVal;
        });
    }

    /*
    Given an array of relationships, get the relationships for all the target objects
    */
    public static getRelationshipsDeep(relns: Relationship[], itemTypeId: number): Promise<Relationship[]> {
        //Extract objectIds
        let itemIds = relns.map((reln) => {
            return reln.target.itemId;
        });
        return RelationshipService.getRelationships(itemIds, itemTypeId).then((resp) => {
            return resp;
        });
    }

    @Timeout
    public static setRelationships(relationships: Relationship[], itemTypeId: number, itemId?: number) {
        let relns: any[] = relationships.slice();
        //Save a list of sourceIds
        let sourceIds: any = [];
        if (itemId) sourceIds.push(itemId);
        relns.forEach((reln) => {
            if (!sourceIds.includes(reln.source.itemId)) sourceIds.push(reln.source.itemId);
        });

        return RelationshipService.getRelationships(sourceIds, itemTypeId).then((existingRelns) => {
            //Compare passed in relationships with existing ones. Applying appropriate statuses. "del"/"new"
            let final: any = {}; //{objectId: [relationship]};
            if (existingRelns.length > 0) {
                existingRelns.forEach((existing) => {
                    if (existing) {
                        let sourceId = existing.source && existing.source.itemId;
                        //Find out if existing exists in the new values (sortedRelns) apply status
                        let index = RelationshipService.find(existing, relns);
                        if (index > -1) {
                            //unchanged relationships
                            existing.status = "est";
                            final[sourceId] = S25Util.array.forceArray(final[sourceId]);
                            final[sourceId].push(existing);
                            S25Util.array.remove(relns, index); //we're done with this one so remove it
                        } else {
                            //relationship is being deleted
                            existing.status = "del";
                            final[sourceId] = S25Util.array.forceArray(final[sourceId]);
                            final[sourceId].push(existing);

                            if (existing.relnType && existing.relnType.itemId === 8) {
                                //substitute with
                                let targetId = existing.target && existing.target.itemId;
                                final[targetId] = S25Util.array.forceArray(final[targetId]);
                                let inverseReln = RelationshipService.reverseRelationship(existing);
                                inverseReln.status = "del";
                                final[targetId].push(inverseReln);
                            }
                        }
                    }
                });
            }

            //anything left in sortedRelns is new
            for (let i = 0; i < relns.length; i++) {
                let sourceId = relns[i].source.itemId;
                relns[i].status = "new";
                if (!final[sourceId]) final[sourceId] = [];
                final[sourceId].push(relns[i]);

                if (relns[i].relnType && relns[i].relnType.itemId === 8) {
                    //substitute with
                    let targetId = relns[i].target && relns[i].target.itemId;
                    final[targetId] = S25Util.array.forceArray(final[targetId]);
                    let inverseReln = RelationshipService.reverseRelationship(relns[i]);
                    inverseReln.status = "new";
                    final[targetId].push(inverseReln);
                }
            }
            //put the data, could need to do a PUT on multiple objects.
            let promiseArr: any = {};
            jSith.forEach(final, (objId, objRelns) => {
                let putService;
                if (itemTypeId == 4) {
                    putService = SpaceService.putSpaceReplace;
                } else if (itemTypeId == 6) {
                    putService = ResourceService.putResourceReplace;
                } else {
                    return null;
                }

                let payload: any = { relationship: RelationshipService.objToNode(objRelns, itemTypeId) };
                promiseArr[objId] = putService(objId, payload);
            });

            return S25Util.all(promiseArr);
        });
    }

    public static updateRelationships(
        ids: number[],
        relatedId: number,
        targetIds: [],
        itemTypeId: number,
        method?: any,
    ) {
        let url = "/space/relationship.json";
        itemTypeId === 6 ? (url = "/resource/relationship.json") : "";
        return DataAccess.put(DataAccess.injectCaller(url, "RelationshipService.updateRelationships"), {
            root: {
                objects: ids.map(function (id) {
                    return { object_id: id };
                }),
                relationships: targetIds.map(function (a: any) {
                    return { relationship_type_id: relatedId, target_id: a.itemId };
                }),
            },
        });
    }

    public static deleteRelationships(
        ids: number[],
        relatedId: number,
        targetIds: [],
        itemTypeId: number,
        method?: any,
    ) {
        let url = "/space/relationship.json";
        itemTypeId === 6 ? (url = "/resource/relationship.json") : "";
        return DataAccess.delete(DataAccess.injectCaller(url, "RelationshipService.deleteRelationships"), {
            root: {
                objects: ids.map(function (id) {
                    return { object_id: id };
                }),
                relationships: targetIds.map(function (a: any) {
                    return { relationship_type_id: relatedId, target_id: a.itemId };
                }),
            },
        });
    }
}
